<div>
  <div class="modal-header">{{'item.version.create.modal.header' | translate}}
    <button type="button" class="close" (click)="onModalClose()" aria-label="Close">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="pb-2">
      {{ "item.version.create.modal.text" | translate }}
      <span *ngIf="!firstVersion">
        {{ "item.version.create.modal.text.startingFrom" | translate : {version: versionNumber} }}
      </span>
    </p>
    <div class="form-group">
      <label for="summary">{{'item.version.create.modal.form.summary.label' | translate }}:</label>
      <input type="text" id="summary" class="form-control" [(ngModel)]="newVersionSummary"
             (keyup.enter)="onModalSubmit()"
             placeholder="{{'item.version.create.modal.form.summary.placeholder' | translate }}"/>
<!--      (keyup.enter)="$event.preventDefault(); $event.stopImmediatePropagation()"-->
    </div>
  </div>
  <div class="modal-footer">
      <button class="btn btn-outline-secondary btn-sm"
              type="button"
              (click)="onModalClose()"
              title="{{'item.version.create.modal.button.cancel.tooltip' | translate}}">
        <i class="fas fa-times fa-fw"></i> {{'item.version.create.modal.button.cancel' | translate}}
      </button>
      <button class="btn btn-success btn-sm"
              type="submit"
              (click)="onModalSubmit()"
              title="{{'item.version.create.modal.button.confirm.tooltip' | translate}}">
        <i class="fas fa-check fa-fw"></i> {{'item.version.create.modal.button.confirm' | translate}}
    </button>
  </div>
</div>
