<video
  #media
  [src]="filteredMedias[currentIndex].bitstream._links.content.href"
  id="singleVideo"
  [poster]="
    filteredMedias[currentIndex].thumbnail ||
    replacements[filteredMedias[currentIndex].format]
  "
  preload="none"
  controls
></video>
<div class="buttons" *ngIf="filteredMedias?.length > 1">
  <button
    class="btn btn-primary previous"
    [disabled]="currentIndex === 0"
    (click)="prevMedia()"
  >
    {{ "media-viewer.previous" | translate }}
  </button>

  <button
    class="btn btn-primary next"
    [disabled]="currentIndex === filteredMedias.length - 1"
    (click)="nextMedia()"
  >
    {{ "media-viewer.next" | translate }}
  </button>
  <div ngbDropdown class="d-inline-block">
    <button
      class="btn btn-outline-primary playlist"
      id="dropdownBasic1"
      ngbDropdownToggle
    >
      {{ "media-viewer.playlist" | translate }}
    </button>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
      <button
        ngbDropdownItem
        *ngFor="let item of filteredMedias; index as indexOfelement"
        class="list-element"
        (click)="selectedMedia(indexOfelement)"
      >
        {{ item.bitstream.name }}
      </button>
    </div>
  </div>
</div>
