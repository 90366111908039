<div>
    <div class="form-row align-items-center">
        <div class="col">
          <input class="form-control"
                 [class.is-invalid]="showErrorMessages"
                 [id]="id"
                 [name]="model.name"
                 [value]="modelValuesString"
                 [disabled]="model.disabled"
                 [type]="model.inputType"
                 [placeholder]="model.placeholder | translate"
                 [readonly]="model.readOnly">
        </div>
    </div>
</div>
