<footer class="text-lg-start">
  <div *ngIf="showTopFooter" class="top-footer">
    <!-- Grid container -->
    <div class=" container p-4">
      <!--Grid row-->
      <div class="row">

        <!--Grid column-->
        <div class="col-lg-4 col-md-6 mb-4 mb-lg-0">
          <h5 class="text-uppercase">Footer Content</h5>

          <ul class="list-unstyled mb-0">
            <li>
              <a routerLink="./" class="">Lorem ipsum</a>
            </li>
            <li>
              <a routerLink="./" class="">Ut facilisis</a>
            </li>
            <li>
              <a routerLink="./" class="">Aenean sit</a>
            </li>
          </ul>
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-lg-4 col-md-6 mb-4 mb-lg-0">
          <h5 class="text-uppercase">Footer Content</h5>

          <ul class="list-unstyled mb-0">
            <li>
              <a routerLink="./" class="">Suspendisse potenti</a>
            </li>
          </ul>
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-lg-4 col-md-12 mb-4 mb-md-0">
          <h5 class="text-uppercase">Footer Content</h5>

          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iste atque ea quis
            molestias. Fugiat pariatur maxime quis culpa corporis vitae repudiandae aliquam
            voluptatem veniam, est atque cumque eum delectus sint!
          </p>
        </div>
        <!--Grid column-->
      </div>
      <!--Grid row-->
    </div>
  </div>

  <!-- Grid container -->
  <div class="text-white" style="display: flex; justify-content: space-between; padding: 0 10px; width: 100%;">
    <div style="align-items: left;">
      <h5 style="text-align: left;">COVE Repository
      </h5>
      <div style="text-align: left;" class="">
        <label>A HealthCOVE Project Initiative
          <br>
          Filipino Nursing Diaspora Network
          <br>
          1/28 Crawford Road, Brighton Le Sands NSW 2216 Australia<br>
          PNSO Office, 2 Rivera Drive, Bajada Davao City, Philippines
        </label>
      </div>
      <div style="text-align: left;" class="">
        <label><i class="fa fa-envelope"></i> E-mail :</label>
        <a class="text-white" href="mailto:adminteam@coverepository.com
          ">adminteam@coverepository.com
        </a>
      </div>
    </div>
  
  </div>

  <!-- Copyright -->
  <div style="background-color:#555; width: 100%;"
    class="container bottom-footer p-1 d-flex text-white container-fluid col-sm-12">
    <div class=" content-container" style="max-width: 100%;">
      <p>
        <!-- <a class="text-white" href="http://www.dspace.org/">{{ 'footer.link.dspace' | translate}}</a>
        {{ 'footer.copyright' | translate:{year: dateObj | date:'y'} }}
        <a class="text-white" href="https://www.lyrasis.org/">{{ 'footer.link.lyrasis' | translate}}</a> -->
        <a style="font-size: medium;" href="https://new.d2t.co/services/service-detail/rapid-dspace">Rapid DSpace</a>
        implemented and maintained by
        <!-- <h1>implemented and maintained by </h1> -->
        <a style="font-size: medium;" href="https://new.d2t.co/">DSquare Technologies</a>
        <strong style="visibility: hidden;">ssdfdsgvdfgfdgdfgfdhuufvfvfdvdfvkfdvkfdvkfhjbhbhfgdfgdfgdfgdfgdfgdfgdfgf</strong>
        Powered by <a style="font-size: medium;" href="https://duraspace.org/dspace/">DSpace </a>
      </p>
      <!-- <ul class="footer-info list-unstyled small d-flex justify-content-center mb-0">
        <li>
          <a class="text-white" href="#" (click)="showCookieSettings()">{{ 'footer.link.cookies' | translate}}</a>
        </li>
        <li>
          <a class="text-white" routerLink="info/privacy">{{ 'footer.link.privacy-policy' | translate}}</a>
        </li>
        <li>
          <a class="text-white" routerLink="info/end-user-agreement">{{ 'footer.link.end-user-agreement' |
            translate}}</a>
        </li>
      </ul> -->
    </div>
  </div>
  <!-- Copyright -->
</footer>