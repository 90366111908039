<div>
    <div class="filters py-2">
        <form #form="ngForm" (ngSubmit)="onSubmit()" class="add-filter row"
              [action]="currentUrl">
            <div class="col-6 form-group mb-0">
                <label>
                  <span class="font-weight-bold">
                    {{'search.filters.filter.' + filterConfig.name + '.min.label' | translate}}
                  </span>
                  <input type="text" [(ngModel)]="range[0]" [name]="filterConfig.paramName + '.min'"
                         class="form-control" (blur)="onSubmit()"
                         aria-label="Mininum value"
                         [placeholder]="'search.filters.filter.' + filterConfig.name + '.min.placeholder' | translate"
                  />
                </label>
            </div>
            <div class="col-6">
                <label>
                    <span class="font-weight-bold">
                      {{'search.filters.filter.' + filterConfig.name + '.max.label' | translate}}
                    </span>
                    <input type="text" [(ngModel)]="range[1]" [name]="filterConfig.paramName + '.max'"
                           class="form-control" (blur)="onSubmit()"
                           aria-label="Maximum value"
                           [placeholder]="'search.filters.filter.' + filterConfig.name + '.max.placeholder' | translate"
                    />
                </label>
            </div>
            <label class="d-none">
                <input type="submit" class="d-none"/>
                <span>{{'search.filters.search.submit' | translate}}</span>
            </label>
        </form>

        <ng-container *ngIf="shouldShowSlider()">
            <nouislider [connect]="true" [min]="min" [max]="max" [step]="1"
                        [dsDebounce]="250" (onDebounce)="onSubmit()"
                        (keydown)="startKeyboardControl()" (keyup)="stopKeyboardControl()"
                        [(ngModel)]="range" ngDefaultControl>
            </nouislider>
        </ng-container>
      <ng-container *ngFor="let page of (filterValues$ | async)?.payload">
        <div [@facetLoad]="animationState">
          <ds-search-facet-range-option *ngFor="let value of page.page; trackBy: trackUpdate" [filterConfig]="filterConfig" [filterValue]="value" [inPlaceSearch]="inPlaceSearch"></ds-search-facet-range-option>
          </div>
        </ng-container>
    </div>
</div>
