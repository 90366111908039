<div class="mt-3" @fadeInOut>
  <ds-eperson-search-box *ngIf="isListOfEPerson" (search)="onSearch($event)"></ds-eperson-search-box>
  <ds-group-search-box *ngIf="!isListOfEPerson" (search)="onSearch($event)"></ds-group-search-box>

  <ds-pagination *ngIf="(getList() | async)?.payload?.totalElements > 0"
                 [paginationOptions]="paginationOptions"
                 [collectionSize]="(getList() | async)?.payload?.totalElements"
                 [retainScrollPosition]="true"
                 [hideGear]="true">

    <div class="table-responsive">
      <table id="groups" class="table table-sm table-striped table-hover table-bordered">
        <thead>
        <tr class="text-center">
          <th>{{'resource-policies.form.eperson-group-list.table.headers.id' | translate}}</th>
          <th>{{'resource-policies.form.eperson-group-list.table.headers.name' | translate}}</th>
          <th>{{'resource-policies.form.eperson-group-list.table.headers.action' | translate}}</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let entry of (getList() | async)?.payload?.page"
            [class.table-primary]="isSelected(entry) | async">
          <td>{{entry.id}}</td>
          <td>{{dsoNameService.getName(entry)}}</td>
          <td class="text-center">
            <button class="btn btn-sm btn-outline-primary" (click)="emitSelect(entry)">
              {{'resource-policies.form.eperson-group-list.select.btn' | translate}}
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

  </ds-pagination>
</div>
