<div class="d-flex">
    <span class="mr-auto text-contents">
        <ng-container *ngIf="!(relatedItem$ | async)">
            <ds-loading [showMessage]="false"></ds-loading>
        </ng-container>
        <ng-container *ngIf="(relatedItem$ | async)">
            <ds-listable-object-component-loader [showLabel]="false" [viewMode]="viewType" [object]="(relatedItem$ | async)"></ds-listable-object-component-loader>
        </ng-container>
    </span>
    <button type="button" class="btn btn-secondary"
            (click)="removeSelection()">
        <i class="fas fa-trash" aria-hidden="true"></i>
    </button>
</div>
